import { lazy } from 'react';

export const Home = lazy(() => import('../modules/home/pages/home'));

{
  /* Diretrizes */
}
export const DiretrizList = lazy(() => import('../modules/planejamento/diretrizes/pages/list'));
export const DiretrizForm = lazy(() => import('../modules/planejamento/diretrizes/pages/form'));
export const DiretrizEdit = lazy(() => import('../modules/planejamento/diretrizes/pages/edit'));
export const DiretrizView = lazy(() => import('../modules/planejamento/diretrizes/pages/view'));

{
  /* Contribuinte */
}
export const ContribuintesManuaisList = lazy(
  () => import('../modules/planejamento/contribuintes-manuais/pages/list')
);
export const ContribuintesManuaisForm = lazy(
  () => import('../modules/planejamento/contribuintes-manuais/pages/form')
);
export const ContribuintesManuaisEdit = lazy(
  () => import('../modules/planejamento/contribuintes-manuais/pages/edit')
);
export const ContribuintesManuaisView = lazy(
  () => import('../modules/planejamento/contribuintes-manuais/pages/view')
);

{
  /* Seleção */
}
export const SelecaoList = lazy(() => import('../modules/planejamento/selecao/pages/list'));
export const SelecaoForm = lazy(() => import('../modules/planejamento/selecao/pages/form'));
export const SelecaoEdit = lazy(() => import('../modules/planejamento/selecao/pages/edit'));
export const SelecaoView = lazy(() => import('../modules/planejamento/selecao/pages/view'));

{
  /*Validacao SAT*/
}
export const ValidacaoSAT = lazy(() => import('../modules/validacao/validacao-sat/pages/list'));
export const ValidacaoSATView = lazy(() => import('../modules/validacao/validacao-sat/pages/view'));
export const ValidacaoSATEdit = lazy(() => import('../modules/validacao/validacao-sat/pages/edit'));

{
  /* Parametros */
}
export const ParametrosForm = lazy(() => import('../modules/configuracoes/parametros/pages/form'));

{
  /* Modelos Mensagem */
}
export const ListModelosMensagem = lazy(
  () => import('../modules/configuracoes/modelos-mensagem/pages/list')
);
export const InclusaoModeloMensagem = lazy(
  () => import('../modules/configuracoes/modelos-mensagem/pages/form')
);
export const EditarModeloMensagem = lazy(
  () => import('../modules/configuracoes/modelos-mensagem/pages/edit')
);

// {
//   /* Contribuintes AR */
// }
export const ListContribuintesAR = lazy(
  () => import('../modules/autorregularizacao/contribuintes-ar/pages/list')
);
export const NovoLoteNotificoesForm = lazy(
  () => import('../modules/autorregularizacao/contribuintes-ar/pages/form')
);
export const EditLoteNotificacoesForm = lazy(
  () => import('../modules/autorregularizacao/contribuintes-ar/pages/edit')
);

// {
//   /*Gestão de Notificação*/
// }
export const ListGestaoNotificacao = lazy(
  () => import('../modules/autorregularizacao/gestao-notificacao/pages/list')
);
export const NovaNotificacaoIndividual = lazy(
  () => import('../modules/autorregularizacao/gestao-notificacao/pages/form')
);
export const ViewAutorregularizacao = lazy(
  () => import('../modules/autorregularizacao/gestao-notificacao/pages/view')
);

// {
//   /* Resposta de Notificação */
// }
export const RespostaNotificacao = lazy(
  () => import('../modules/autorregularizacao/resposta-notificacao/pages/form')
);

// {
//   /* Atribuir Notificação */
// }
export const AtribuirNotificacao = lazy(
  () => import('../modules/autorregularizacao/atribuir-notificacao/pages/form')
);

// {
//   /* Editar Notificação Individual*/
// }
export const EditarNotificacaoIndividual = lazy(
  () => import('../modules/autorregularizacao/gestao-notificacao/pages/edit')
);

{
  /* Gerencial */
}
export const Arrecadacao = lazy(() => import('../modules/gerencial/arrecadacao/pages/form/index'));
export const Paineis = lazy(() => import('../modules/gerencial/paineis/pages/list/index'));
export const ListagemOrdensServicoByServidor = lazy(
  () => import('../modules/gerencial/ordens/pages/list/servidor/index')
);
export const ListagemOrdensServico = lazy(() => import('../modules/gerencial/ordens/pages/list'));

{
  /* Contribuintes */
}
export const Notificacoes = lazy(
  () => import('../modules/contribuinte/notificacoes/pages/list/index')
);
export const JustificarNotificacao = lazy(
  () => import('../modules/contribuinte/notificacoes/pages/justificar-notificacao/index')
);

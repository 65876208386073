import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '../modules/_shared/components/layout/main-layout';
import { OrdensFiltrosProvider } from '../modules/gerencial/ordens/contexts/filtros.context';
import Logout from '../modules/home/pages/logout';
import SessionExpired from '../modules/home/pages/session-expired';
import Unauthorized from '../modules/home/pages/unauthorized';
import PrivateRoute from './private.routes';
import * as L from './lazy-loading';
import PageRoutes from '../modules/_shared/constants/enum/page-routes.enum';

const AppRoutes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <MainLayout>
          <PrivateRoute path={PageRoutes.LOGOUT} exact component={Logout} />
          <PrivateRoute path={PageRoutes.UNAUTHORIZED} exact component={Unauthorized} />
          <PrivateRoute path={PageRoutes.SESSION_EXPIRED} exact component={SessionExpired} />
          <PrivateRoute path={PageRoutes.HOME} exact component={L.Home} />

          <Route
            path={'/diretrizes'}
            component={() => (
              <>
                <PrivateRoute path={PageRoutes.CREATE_DIRETRIZ} exact component={L.DiretrizForm} />
                <PrivateRoute path={PageRoutes.EDIT_DIRETRIZ} exact component={L.DiretrizEdit} />
                <PrivateRoute path={PageRoutes.VIEW_DIRETRIZ} exact component={L.DiretrizView} />
                <PrivateRoute path={PageRoutes.LIST_DIRETRIZES} exact component={L.DiretrizList} />
              </>
            )}
          />

          <Route
            path={'/contribuinte/manual'}
            component={() => (
              <>
                <PrivateRoute
                  path={PageRoutes.LIST_CONTRIBUINTE_MANUAL}
                  exact
                  component={L.ContribuintesManuaisList}
                />
                <PrivateRoute
                  path={PageRoutes.CREATE_CONTRIBUINTE_MANUAL}
                  exact
                  component={L.ContribuintesManuaisForm}
                />
                <PrivateRoute
                  path={PageRoutes.EDIT_CONTRIBUINTE_MANUAL}
                  exact
                  component={L.ContribuintesManuaisEdit}
                />

                <PrivateRoute
                  path={PageRoutes.VIEW_CONTRIBUINTE_MANUAL}
                  exact
                  component={L.ContribuintesManuaisView}
                />
              </>
            )}
          />

          <Route
            path={'/selecao'}
            component={() => (
              <>
                <PrivateRoute path={PageRoutes.LIST_SELECAO} exact component={L.SelecaoList} />
                <PrivateRoute path={PageRoutes.CREATE_SELECAO} exact component={L.SelecaoForm} />
                <PrivateRoute path={PageRoutes.EDIT_SELECAO} exact component={L.SelecaoEdit} />
                <PrivateRoute path={PageRoutes.VIEW_SELECAO} exact component={L.SelecaoView} />
              </>
            )}
          />

          <Route
            path={'/validacao-sat'}
            component={() => (
              <>
                <PrivateRoute
                  path={PageRoutes.LIST_VALIDACAO_SAT}
                  exact
                  component={L.ValidacaoSAT}
                />
                <PrivateRoute
                  path={PageRoutes.VIEW_VALIDACAO_SAT}
                  exact
                  component={L.ValidacaoSATView}
                />
                <PrivateRoute
                  path={PageRoutes.EDIT_VALIDACAO_SAT}
                  exact
                  component={L.ValidacaoSATEdit}
                />
              </>
            )}
          />

          <Route
            path={'/modelos-mensagem'}
            component={() => (
              <>
                <PrivateRoute
                  path={PageRoutes.LIST_MODELO_MENSAGEM}
                  exact
                  component={L.ListModelosMensagem}
                />
                <PrivateRoute
                  path={PageRoutes.CREATE_MODELO_MENSAGEM}
                  exact
                  component={L.InclusaoModeloMensagem}
                />
                <PrivateRoute
                  path={PageRoutes.EDIT_MODELO_MENSAGEM}
                  exact
                  component={L.EditarModeloMensagem}
                />
              </>
            )}
          />

          <Route
            path={'/contribuintes-ar'}
            component={() => (
              <>
                <PrivateRoute
                  path={PageRoutes.LIST_CONTRIBUINTESAR}
                  exact
                  component={L.ListContribuintesAR}
                />
                <PrivateRoute
                  path={PageRoutes.CREATE_NOVO_LOTE_NOTIFICACOES}
                  exact
                  component={L.NovoLoteNotificoesForm}
                />
                <PrivateRoute
                  path={PageRoutes.EDIT_LOTE_NOTIFICACOES}
                  exact
                  component={L.EditLoteNotificacoesForm}
                />
              </>
            )}
          />

          <Route
            path={'/gestao-notificacao'}
            component={() => (
              <>
                <PrivateRoute
                  path={PageRoutes.LIST_CONTRIBUINTESAR}
                  exact
                  component={L.ListContribuintesAR}
                />
                <PrivateRoute
                  path={PageRoutes.LIST_GESTAO_NOTIFICACAO}
                  exact
                  component={L.ListGestaoNotificacao}
                />
                <PrivateRoute
                  path={PageRoutes.CREATE_NOTIFICACAO_INDIVIDUAL}
                  exact
                  component={L.NovaNotificacaoIndividual}
                />
                <PrivateRoute
                  path={PageRoutes.VIEW_AUTORREGULARIZACAO}
                  exact
                  component={L.ViewAutorregularizacao}
                />
                <PrivateRoute
                  path={PageRoutes.CREATE_RESPOSTA_NOTIFICACAO}
                  exact
                  component={L.RespostaNotificacao}
                />
                <PrivateRoute
                  path={PageRoutes.ATRIBUIR_NOTIFICACAO}
                  exact
                  component={L.AtribuirNotificacao}
                />
                <PrivateRoute
                  path={PageRoutes.EDIT_NOTIFICACAO_INDIVIDUAL}
                  exact
                  component={L.EditarNotificacaoIndividual}
                />
              </>
            )}
          />

          {/* Parametros */}
          <PrivateRoute path={PageRoutes.EDIT_PARAMETROS} exact component={L.ParametrosForm} />

          {/*Gerencial*/}
          {/*Arrecação*/}
          <PrivateRoute path={PageRoutes.ARRECADACAO} exact component={L.Arrecadacao} />
          <PrivateRoute path={PageRoutes.PAINEIS} exact component={L.Paineis} />
          <Route
            path="/ordens"
            component={() => (
              <OrdensFiltrosProvider>
                <PrivateRoute path={PageRoutes.ORDENS} exact component={L.ListagemOrdensServico} />
                <PrivateRoute
                  path={PageRoutes.LISTAGEM_ORDENS_SERVICO_BY_SERVIDOR}
                  exact
                  component={L.ListagemOrdensServicoByServidor}
                />
              </OrdensFiltrosProvider>
            )}
          />

          {/*Contribuintes*/}
          {/*Notificações*/}
          <PrivateRoute path={PageRoutes.LIST_NOTIFICACOES} exact component={L.Notificacoes} />
          <PrivateRoute
            path={PageRoutes.JUSTIFICAR_NOTIFICACAO}
            exact
            component={L.JustificarNotificacao}
          />
          <PrivateRoute
            path={PageRoutes.VIEW_AUTORREGULARIZACAO_CONTRIBUINTE}
            exact
            component={L.ViewAutorregularizacao}
          />
        </MainLayout>
        <Redirect to={PageRoutes.HOME} />
      </Switch>
    </HashRouter>
  );
};

export default AppRoutes;
